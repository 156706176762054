import { createContext, useContext, useState, useEffect } from 'react';
import { useSearchParams, useParams, createSearchParams } from 'react-router-dom';
import { Snackbar, Button, Box, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { useConfigureAxios, axiosInstance } from './axios';

import { useNavigate } from 'react-router-dom';

import { payLaterAPI } from '@lendica/select-payment-terms';
import { accessToken, useAccountContext, AccountProvider } from '@lendica/auth';

const AppDataContext = createContext();

export const useAppDataContext = () => {
    return useContext(AppDataContext);
};

const useStyles = makeStyles(theme => ({
    snackbarButton: {
        color: theme.palette.error.main,
    },
}));

let windowObjectReference = null;
let previousURL;

function openRequestedSingleTab(url) {
    if (windowObjectReference === null || windowObjectReference.closed) {
        windowObjectReference = window.open(url, 'LendicaPayLater');
    } else if (previousURL !== url) {
        windowObjectReference = window.open(url, 'LendicaPayLater');
        /* if the resource to load is different,
         then we load it in the already opened secondary window and then
         we bring such window back on top/in front of its parent window. */
        windowObjectReference.focus();
    } else {
        windowObjectReference.focus();
    }
    previousURL = url;
    /* explanation: we store the current url in order to compare url
       in the event of another call of this function. */
}

function storageAvailable(type) {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        console.log(e);
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
}

export const AppData = ({ children }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        isLoading: false,
        errorMsg: null,
        redirect: null,
    });
    const [error, setError] = useState();

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { clearAccounts } = useAccountContext();

    // Set up axios interceptors
    const onRequestError = async e => {
        // if (e.response && e.response.status === 401) {
        //     return Promise.reject(e);
        // }
        if (e.response && e.response.status === 401) {
            await new Promise(async resolve => {
                await clearAccounts();
                setTimeout(() => resolve('removed!'), 500);
            }).then(() => {
                navigate(`${state?.bill?.id}/select-terms/preview`);
            });
            return Promise.resolve('cleared');
        }
        if (!axios.isCancel(e)) {
            setError(true);
        }
        return Promise.reject(e);
    };
    const onRefreshFailue = () => {
        return;
    };
    const isConfigured = useConfigureAxios({
        onRequestError,
        onRefreshFailue,
    });

    const setLoading = isLoading => {
        setState(currentValue => ({ ...currentValue, isLoading }));
    };
    const setData = data => {
        setState(currentValue => ({ ...currentValue, ...data }));
    };
    const setErrorMsg = errorMsg => {
        setState(currentValue => ({ ...currentValue, errorMsg }));
    };
    const warnIfNoLocalStorage = () => {
        if (!storageAvailable('localStorage')) {
            const handle = openRequestedSingleTab(location);
            if (!handle) {
                setError(true);
                setErrorMsg('Please enable popup for this site, and refresh the page.');
                console.warn('Please enable popup for this site, and refresh the page.');
            }
            return;
        }
    };
    const interceptIfTokenExists = () => {
        if (!!accessToken && !!accessToken?.get()) {
            console.info('User is logged in');
            return true;
        }
        return false;
    };

    const loadPartnerData = async () => {
        try {
            const data = (
                await axiosInstance.get(`/paylater/partner`, {
                    params: searchParams, // partner_name, partner_invoice_uuid, and any additional params passed to frontend, including partner_company_uuid if exists
                })
            ).data;

            if (!data) {
                throw new Error('Invalid params provided');
            }

            data?.offers?.sort((a, b) => (a.number_of_payments > b.number_of_payments ? 1 : -1));
            setData(data);

            if (!!data?.deal) {
                navigate(`/${data?.bill?.id}/submitted`);
            } else {
                navigate(`/${data?.bill?.id}/select-terms/preview`);
            }
        } catch (e) {
            console.log(e);
            navigate('/error');
        }
    };

    useEffect(() => {
        setLoading(true);
        warnIfNoLocalStorage();
        if (searchParams.has('partner_name') && searchParams.has('partner_invoice_uuid')) {
            (async () => {
                await loadPartnerData();
                setLoading(false);
            })();
        } else {
            console.log('no partner_name or partner_invoice_uuid in url params');
            setLoading(false);
            // navigate('/home');
        }
    }, []);

    return (
        isConfigured && (
            <AppDataContext.Provider
                value={{
                    ...state,
                    setLoading,
                    // loadPartnerData,
                    interceptIfTokenExists,
                    async loadBill(billId) {
                        // setLoading(true);
                        try {
                            const bill = await payLaterAPI.getBill(billId);
                            setState(currentValue => ({ ...currentValue, bill }));
                        } catch (e) {
                            navigate('/error');
                        } finally {
                            // setLoading(false);
                        }
                    },
                    async addBill(billId, offerId) {
                        // setLoading(true);
                        try {
                            const data = await payLaterAPI.addBill(billId);
                            setState(currentValue => ({ ...currentValue, ...data }));
                            const isShopify =
                                data?.company &&
                                [
                                    'altametrics',
                                    'plum',
                                    'teaica',
                                    'black label',
                                    'blacklabel',
                                    'blackreplica',
                                    'vizocom',
                                ].some(vendor =>
                                    data?.company?.partner_name?.toLowerCase().includes(vendor)
                                );
                            const redirectLinks = {
                                application: [
                                    `/${billId}/${offerId}/application`,
                                    `/${billId}/${offerId}/shipping-info/${billId}`,
                                ],
                                offers: [
                                    `/${billId}/select-terms/final`,
                                    `/${billId}/${offerId}/shipping-info/${billId}`,
                                ],
                                wait: [`/${billId}/pending`, `/${billId}/shop/pending`],
                                rejected: [`/${billId}/declined`, `/${billId}/shop/sorry`],
                            };
                            setState(currentValue => ({
                                ...currentValue,
                                redirect: redirectLinks[data?.next_step][+isShopify],
                            }));
                        } catch (e) {
                            navigate('/error');
                        } finally {
                            // setLoading(false);
                        }
                    },
                    async loadOffers(billId) {
                        // setLoading(true);
                        try {
                            const { offers } = await payLaterAPI.getOffers(billId);
                            offers?.sort((a, b) =>
                                a.number_of_payments > b.number_of_payments ? 1 : -1
                            );
                            setState(currentValue => ({ ...currentValue, offers }));
                        } catch (e) {
                            navigate('/error');
                        } finally {
                            // setLoading(false);
                        }
                    },
                    async createDeal(billId, offerId, onAgree) {
                        // setLoading(true);
                        try {
                            const data = await payLaterAPI.createDeal(billId, offerId);
                            setState(currentValue => ({
                                ...currentValue,
                                ...data,
                            }));
                            onAgree(billId);
                        } catch (e) {
                            if (e.response) {
                                if (e.response.status === 400) {
                                    navigate('/limit');
                                    setState(currentValue => ({
                                        ...currentValue,
                                        isLoading: false,
                                    }));
                                    return;
                                }
                            }
                            navigate('/error');
                        } finally {
                            // setLoading(false);
                        }
                    },
                    async getCompanyDetails(billId) {
                        // setLoading(true);
                        try {
                            const company = (await axios.get('/company/details')).data;
                            if (!company?.phone_number) {
                                await clearAccounts();
                                navigate(`/${billId}/select-terms/preview`);
                            } else {
                                // console.log('company has phone', company);
                                // console.log('state', state);
                                setState(currentValue => ({ ...currentValue, company }));
                            }
                        } catch (e) {
                            navigate('/error');
                        } finally {
                            // setLoading(false);
                        }
                    },
                    onRequestError,
                }}
            >
                {children}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={error}
                    autoHideDuration={6000}
                    onClose={() => {
                        setError(false);
                    }}
                    message={
                        <Box sx={{ width: 300 }}>{state.errorMsg || `Something went wrong :(`}</Box>
                    }
                    action={
                        <>
                            <Button
                                className={classes.snackbarButton}
                                size="small"
                                onClick={() => {
                                    setError(false);
                                }}
                            >
                                OK
                            </Button>
                        </>
                    }
                />
            </AppDataContext.Provider>
        )
    );
};

export const AuthContainer = ({ children }) => {
    const navigate = useNavigate();
    const { billId } = useParams();

    return (
        <AccountProvider
            onAuthSuccess={() => {
                billId ? navigate(`/${billId}/bill`) : () => null;
            }}
            onAccountClear={() => {
                billId ? navigate(`/${billId}/bill`) : () => null;
            }}
        >
            {children}
        </AccountProvider>
    );
};
